import * as React from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Grid from "@material-ui/core/Grid";
import { Layout } from "../../components/Layout";
import { CardMedia } from "@material-ui/core";
import { Slide } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Seo from "../../components/Seo/Seo";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import { CallbackBanner } from "../../components/CallbackBanner";

import HouseKeepingImg from "../../images/service-grid/bed_tidying.jpeg";
import MopIcon from "../../assests/svg/cleaning-mop.svg";

import { useStyles } from "../../pagesStyles/aboutUs.styling";

const HouseKeeping = () => {
  const classes = useStyles();
  const desktop = useMediaQuery("(min-width:769px)");

  const breadcrumbs = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "House Keeping & Companionship",
    },
  ];

  const listItems = [
    "Our House Keeping Services include the following",
    "Collecting Prescriptions",
    "Dusting & Vacuuming",
    "Help with washing and ironing",
    "Making beds & changing linen",
    "Companionship",
    "Cooking/Preparing meals",
    "Checking Food Expiry Dates",
    "Oversee Home Deliveries",
    "Watering of indoor plants",
    "Pet Care/Dog Walking",
    "Help with general shopping",
    "Arranging & escorting to appointments",
    "Participating in hobbies & crafts",
  ];
  return (
    <Layout gutter marginTop breadcrumb={breadcrumbs}>
      <Seo title="House Keeping" />
      <section style={{ padding: desktop ? 0 : "0 5px"  }}>
        <Grid container direction={desktop ? "row" : "column"} spacing={5}>
          <Grid item xs={desktop ? 6 : 12}>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              <strong>House Keeping & Companionship</strong>
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Due to current experience in Care and Home Help Services we have
              expanded our business into Cleaning and House-Keeping Services.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Using our Cloud Technology systems, we can monitor the length of
              the call agreed by our customers.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              We take hygiene extremely seriously and our staff will be wearing
              the appropriate attire for the visit, ie; gloves, aprons, masks
              and washing hands regularly.
            </Typography>
            <Typography variant="h5" style={{ marginBottom: "1.5rem" }}>
              Whilst carrying out cleaning in your home we can also make you a
              cup of tea/coffee and sit and make time for conversation. We
              understand that there are many clients who live alone and can go
              days if not weeks without seeing anyone. Our team are happy to
              chat about the latest TV programme or reminisce with you about the
              past.
            </Typography>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <Slide direction="right" in timeout={700}>
              <CardMedia
                style={{ height: "60vh" }}
                image={HouseKeepingImg}
                // alt={altTag}
                classes={{ root: classes.cardImageL }}
                component="img"
              />
            </Slide>
          </Grid>
        </Grid>
      </section>
      <section style={{ padding: desktop ? "5rem 0" : "2rem 5px"  }}>
      <Typography variant="h5" gutterBottom>
            <strong>Our House Keeping Services include the following:</strong>
          </Typography>
        <Grid container direction={desktop ? "row" : "column"}>
          <Grid item xs={desktop ? 6 : 12}>
            <List aria-label="house keeping services">
              {listItems.map((item, index) => {
                return (
                  index + 1 <= listItems.length / 2 && (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <MopIcon style={{ width: "2rem" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Grid>
          <Grid item xs={desktop ? 6 : 12}>
            <List aria-label="house keeping services continued" style={{ paddingTop: !desktop && 0 }}>
              {listItems.map((item, index) => {
                return (
                  index + 1 > listItems.length / 2 && (
                    <ListItem key={item}>
                      <ListItemIcon>
                        <MopIcon style={{ width: "2rem" }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Grid>
        </Grid>
      </section>
      <CallbackBanner/>
    </Layout>
  );
};

export default HouseKeeping;
